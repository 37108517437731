<template>
  <div class="main">
    <Navigation />
    <div class="container mt-4" v-if="churchView == 0">
      <div class="row mt-4">
        <div class="col-md-5">
          <div class="form-group">
            <label>Operations:</label>
            <select
              class="form-control"
              id="optionOperation"
              v-model="selectedOperation"
            >
              <option v-bind:key="data.id" v-for="data in Operation">
                {{ data.Operation }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-5">
          <div class="form-group">
            <label>Region:</label>
            <select
              class="form-control"
              id="optionProvincial"
              v-model="selectedRegion"
            >
              <option v-bind:key="data.id" v-for="data in Provincial">
                {{ data.Province }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-5">
          <div class="form-group">
            <label>District:</label>
            <select
              class="form-control"
              id="optionDistrict"
              v-model="selectedDistrict"
            >
              <option v-bind:key="data.id" v-for="data in Districts">
                {{ data.district }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2 d-flex flex-row-reverse">
        <div class="col-md-5 d-flex flex-row-reverse">
          <button class="btn btn-success" v-on:click="onChange('ADDNEW', '')">
            Add New
          </button>
        </div>
      </div>
      <hr />
      <div class="table-responsive">
        <table class="table table-md table-hover">
          <thead>
            <tr>
              <th scope="col">Church Name</th>
              <th scope="col">Church Pastor</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="data.Churchid" v-for="data in churchList">
              <td>{{ data.ChurchName }}</td>
              <td>{{ data.ChurchPastor }}</td>
              <td>
                <button
                  class="btn btn-primary"
                  v-on:click="onChange('UPDATE', data)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                </button>
                <button
                  class="btn btn-danger margin-left-10"
                  v-on:click="DELETE(data.Id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="container" v-else>
      <div class="row mt-5">
        <div class="col-md-6">
          <div class="form-group">
            <label>Operation</label>
            <select
              class="form-control"
              id="optionOperation"
              v-model="selectedOperation"
              v-bind:disabled="churchView == 1"
            >
              <option v-bind:key="data.id" v-for="data in Operation">
                {{ data.Operation }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="form-group">
            <label>Region</label>
            <select
              class="form-control"
              id="optionProvincial"
              v-model="selectedRegion"
              v-bind:disabled="churchView == 1"
            >
              <option v-bind:key="data.id" v-for="data in Provincial">
                {{ data.Province }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="form-group">
            <label>District</label>
            <select
              class="form-control"
              id="optionDistrict"
              v-model="selectedDistrict"
              v-bind:disabled="churchView == 1"
            >
              <option v-bind:key="data.id" v-for="data in Districts">
                {{ data.district }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="form-group">
            <label>Church Name</label>
            <input
              type="text"
              v-model="churchName"
              class="form-control"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mt-2">
          <div class="form-group">
            <label>Church Pastor</label>
            <input
              type="text"
              v-model="churchPastor"
              class="form-control"
              required
            />
          </div>
        </div>
      </div>
      <div class="row mt-5 d-flex justify-content-end">
        <div class="col-md-5 d-flex justify-content-evenly">
          <button
            type="submit"
            class="btn btn-success w-25"
            v-on:click="Insert"
            v-if="churchView == 1"
          >
            Insert
          </button>
          <button
            type="submit"
            class="btn btn-primary w-25"
            v-on:click="Update"
            v-if="churchView == 2"
          >
            Update
          </button>
          <button
            class="btn btn-danger ml-4 w-25"
            v-on:click="onChange('CANCEL', '')"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Navigation from "../components/Navigation.vue";
export default {
  name: "Home",
  components: {
    Navigation,
  },
  created() {
    var encoded = localStorage.getItem("log");
    var actual = JSON.parse(atob(encoded));
    this.$store.commit("SET_USERLOGON", actual);
    var encoded = localStorage.getItem("ch");
    var actual = JSON.parse(atob(encoded));
    this.$store.commit("SET_CHURCHES", actual);
    this.GETALL();
  },
  data() {
    return {
      selectedChurch: {},
      churchView: 0,
      churchId: 0,
      churchPastor: "",
      churchName: "",
      isVisible: 0,
      selectedOperation: "JIL Southern and Eastern Europe",
      selectedRegion: "North West",
      selectedDistrict: "Piemonte",
      Operation: [
        {
          id: 0,
          Operation: "JIL Southern and Eastern Europe",
        },
      ],
      Provincial: [
        {
          id: "NW",
          Province: "North West",
        },
        {
          id: "NE",
          Province: "North East",
        },
        {
          id: "C",
          Province: "Central",
        },
        {
          id: "S",
          Province: "South",
        },
        {
          id: "I",
          Province: "Islands",    
        },
      ],
      Churches: [],
    };
  },
  computed: {
    churchList: function () {
      let arr = [];
      this.Churches.forEach((element) => {
        if (
          this.selectedOperation == element.Operation &&
          this.selectedRegion == element.Provincial &&
          this.selectedDistrict == element.District
        ) {
          arr.push(element);
        }
      });
      return arr;
    },
    Districts:function(){
      let arr = [];    
        if(this.selectedRegion == "North West"){      
          arr.push({id:"P1",district:'Piemonte'});
          arr.push({id:"VD",district:"Valle D'aosta"});
          arr.push({id:"Lig",district:'Liguria'});
          arr.push({id:"Lomba",district:'Lombardia'});    
        }
        else if(this.selectedRegion == "North East"){      
          arr.push({id:"TR",district:"Trentino"});
          arr.push({id:"VN",district:"Veneto"});
          arr.push({id:"FR",district:"Friuli"});
          arr.push({id:"ER",district:'Emilia Romagna'});    
        }
        else if(this.selectedRegion =="Central"){
           arr.push({id:"TO",district:"Toscana"});
           arr.push({id:"U",district:"Umbria"});
           arr.push({id:"MA",district:"Marche"});
           arr.push({id:"LA",district:"Lazio"});
        }
        else if(this.selectedRegion == "South")
        {
           arr.push({id:"AB",district:"Abruzzo"});
           arr.push({id:"MO",district:"Molise"});
           arr.push({id:"CA",district:"Campania"});
           arr.push({id:"PU",district:"Puglia"});
           arr.push({id:"BA",district:"Basilicata"});
           arr.push({id:"CA",district:"Calabria"});
        }
        else if(this.selectedRegion =="Islands"){
           arr.push({id:"SC",district:"Sicilia"});
           arr.push({id:"SA",district:"Sardegna"});       
        }
        this.selectedDistrict = arr[0].district;
        return arr;
    }
  },
  methods: {
    onChange(value, param_data) {
      if (value == "ADDNEW") {
        this.churchView = 1;
      } else if (value == "UPDATE") {
        this.churchView = 2;
        this.selectedChurch = param_data;
        this.churchPastor = param_data.ChurchPastor;
        this.churchName = param_data.ChurchName;
      } else {
        this.churchView = 0;
      }
    },
    GETALL() {
      this.Churches = [];
      axios
        .get("https://api.jilcwseeo.org/api/Church/getChurches")
        .then((response) => {
          let obj = response.data;
          if (obj.length > 0) {
            for (let i = 0; i <= obj.length - 1; i++) {
              let obj_data = obj[i];
              var church_obj = {
                ChurchName: obj_data.churchName,
                ChurchPastor: obj_data.churchPastor,
                Provincial: obj_data.provincial,
                Operation: obj_data.operation,
                District: obj_data.district,
                Id: obj_data.id,
                ChurchId: obj_data.churchId,
              };
              this.Churches.push(church_obj);
            }
            this.$store.commit("SET_CHURCHES", this.Churches);
          }
        });
    },
    RESET() {
      this.churchView = 0;
      this.churchPastor = "";
      this.churchName = "";
      this.isVisible = 0;
      this.selectedOperation = "JIL Southern and Eastern Europe";
      this.GETALL();
    },
    Insert() {
      if (this.churchName != "" && this.churchPastor != "") {
        var obj_church = {
          ChurchName: this.churchName,
          ChurchPastor: this.churchPastor,
          Provincial: this.selectedRegion,
          Operation: this.selectedOperation,
          District: this.selectedDistrict,
        };
        axios
          .post("https://api.jilcwseeo.org/api/Church/insert-church", obj_church)
          .then((response) => {
            if (response.status == 200) {
              this.SWEETALERT("Success", "Record has been added.", "success");
              this.RESET();
            } else {
              this.SWEETALERT("Error", "Something went wrong.", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              this.SWEETALERT("Error", "Something went wrong.", "error");
            }
          });
      } else {
        Swal.fire({
          title: "Please fill up all textfield.",
          icon: "warning",
          confirmButtonColor: "#0d6efd",
        });
      }
    },
    Update() {
      if (this.churchName != "" && this.churchPastor != "") {
        swal
          .fire({
            text: "Do you want to continue?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonColor: "#0d6efd",
            cancelButtonColor: "#dc3545",
          })
          .then((result) => {
            if (result.isConfirmed) {
              var obj_church = {
                Id: this.selectedChurch.Id,
                ChurchName: this.churchName,
                ChurchPastor: this.churchPastor,
                Provincial: this.selectedRegion,
                Operation: this.selectedOperation,
                District: this.selectedDistrict,
              };
              axios
                .patch(
                  "https://api.jilcwseeo.org/api/Church/update-church",
                  obj_church
                )
                .then((response) => {
                  if (response.status == 200) {
                    this.SWEETALERT(
                      "Success",
                      "Record has been updated.",
                      "success"
                    );
                    this.RESET();
                  } else {
                    this.SWEETALERT("Error", "Something went wrong.", "error");
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    this.SWEETALERT("Error", "Something went wrong.", "error");
                  }
                });
            }
          });
      } else {
        Swal.fire({
          title: "Please fill up all textfield.",
          icon: "warning",
          confirmButtonColor: "#0d6efd",
        });
      }
    },
    DELETE(param) {
      swal
        .fire({
          title: "Are you sure you want to delete?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonColor: "#0d6efd",
          cancelButtonColor: "#dc3545",
        })

        .then((result) => {
          if (result.isConfirmed) {
            var obj_church = {
              Id: param,
            };
            axios
              .post(
                "https://api.jilcwseeo.org/api/Church/delete-church",
                obj_church
              )
              .then((response) => {
                if (response.status == 200) {
                  this.SWEETALERT(
                    "Success",
                    "Church has been deleted.",
                    "success"
                  );
                  this.GETALL();
                } else {
                  this.SWEETALERT("Error", "Something went wrong.", "error");
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.SWEETALERT("Error", "Something went wrong.", "error");
                }
              });
          }
        });
    },
    SWEETALERT(param_title, param_text, param_action) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: param_action,
        title: param_title,
        text: param_text,
      });
    },
  },
};
</script>
<style scoped>
.margin-left-10 {
  margin-left: 10px;
}
table {
  width: 100%;
}
thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
tbody {
  display: block;
  overflow-y: auto;
  table-layout: fixed;
  max-height: 400px;
}
@media screen and (max-width: 762px) {
  .margin-left-10 {
    margin-left: none !important;
  }
}
</style>